import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userInfo: [],
};

export const loginUser = createSlice({
  name: "UserInfo",
  initialState,
  reducers: {
    loginUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
  },
});

export const { loginUserInfo } = loginUser.actions;

export default loginUser.reducer;
