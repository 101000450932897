export default {
  // ----logo-images-start---- //
  logo: {
    logo_1: require("../assets/svgs/pcomssb.svg"),
    logo_2: require("../assets/svgs/pcomssb.svg"),
    logo_3: require("../assets/svgs/pcomssb.svg"),
  },
  mask: {
    mask_1: require("../assets/svgs/Mask-1.svg"),
  },
  fileSvg: require("../assets/pngs/file.png"),
  barDotted: require("../assets/svgs/bars-dot.svg"),
  report: require("../assets/svgs/report.svg"),
  systemSettings: require("../assets/svgs/system-settings.svg"),
  updateDocs: require("../assets/svgs/update-doc.svg"),
  progress: require("../assets/svgs/progress.svg"),
  pending: require("../assets/svgs/pending.svg"),
  sortBars: require("../assets/svgs/sort-bars.svg"),
  upload: require("../assets/pngs/upload.png"),
  pngFile: require("../assets/svgs/png-file.svg"),
  qrCode: require("../assets/pngs/qr-code.png"),
  tickRound: require("../assets/svgs/tick.svg"),
  singpass: {
    logo: require("../assets/svgs/singpass-logo.svg"),
    logo_1: require("../assets/svgs/singpass-white-logo.svg"),
    character: require("../assets/svgs/character.svg"),
    qrCode: require("../assets/pngs/qr-code.png"),
  },
  user: require("../assets/pngs/user.png"),
  // ----logo-images-end---- //
};
