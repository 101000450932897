import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  formData: {},
};

export const filterForm = createSlice({
  name: "FilterData",
  initialState,
  reducers: {
    filterFormData: (state, action) => {
      state.formData = action.payload;
    },
  },
});

export const { filterFormData } = filterForm.actions;

export default filterForm.reducer;
