import React from "react";
import Routes from "./routes/routes";
import "../node_modules/bootstrap-4-react/dist/bootstrap-4-react";

function App() {
  return (
    <>
      <Routes />
    </>
  );
}

export default App;
