import React, { Suspense, lazy } from "react";
// ----3rd-party---- //
import { Routes, Route } from "react-router-dom";
// ----Component-for-scroll-to-top---- //
import ScrollToTop from "../components/ScrollToTop/ScrollToTop";
// ----Loader---- //
import Loader from "../components/Loader";
// ----pages---- //
const Login = lazy(() => import("../pages/Login"));
// ----Dashboard-Pages---- //
const Home = lazy(() => import("../pages/Dashboard/Home"));
const HpApplication = lazy(() => import("../pages/Dashboard/HpApplication"));
const CreateApplication = lazy(() =>
  import("../pages/Dashboard/CreateApplication")
);
const PersonalSingpassLogin = lazy(() =>
  import("../pages/SingpassLogin/personalLogin")
);
const CompanySingpassLogin = lazy(() =>
  import("../pages/SingpassLogin/companyLogin")
);
const SingpassPersonalLoginDetails = lazy(() =>
  import("../pages/SingpassLoginDetails/personalLoginDetails")
);
const SingpassCompanyLoginDetails = lazy(() =>
  import("../pages/SingpassLoginDetails/companyLoginDetails")
);
const HpApplicationAddDoc = lazy(() =>
  import("../pages/Dashboard/HpApplicationAddDoc")
);
const HpApplicationApprovalAdvice = lazy(() =>
  import("../pages/Dashboard/HpApplicationApprovalAdvice")
);
const HpApplicationAccept = lazy(() =>
  import("../pages/Dashboard/HpApplicationAccept")
);
const HpApplicationReject = lazy(() =>
  import("../pages/Dashboard/HpApplicationReject")
);
const HpApplicationApeal = lazy(() =>
  import("../pages/Dashboard/HpApplicationApeal")
);
const ViewApplication = lazy(() =>
  import("../pages/Dashboard/ViewApplication")
);
const NewViewApplication = lazy(() => import("../pages/Dashboard/NewViewPage"));
const EditHpApplication = lazy(() =>
  import("../pages/Dashboard/EditHpApplication")
);
const CreateGuarantor = lazy(() =>
  import("../pages/Dashboard/CreateGuarantor")
);
const Accounts = lazy(() => import("../pages/Dashboard/Accounts"));
const Reports = lazy(() => import("../pages/Dashboard/Report"));
//config
import config from "../config/config.json";

const url = config.routeUrl;

export default function routes() {
  return (
    <>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path={url} element={<Login />} />
          <Route path={url + "home"} element={<Home />} />
          <Route path={url + "hp-applications"} element={<HpApplication />} />
          <Route
            path={url + "hp-applications/create/:singpassUrl"}
            element={<CreateApplication />}
          />
          <Route
            path={url + "personal-signpass/login"}
            element={<PersonalSingpassLogin />}
          />
          <Route
            path={url + "company-signpass/login"}
            element={<CompanySingpassLogin />}
          />
          <Route
            path={url + "company-signpass/login/details"}
            element={<SingpassCompanyLoginDetails />}
          />
          <Route
            path={url + "personal-signpass/login/details"}
            element={<SingpassPersonalLoginDetails />}
          />
          <Route
            path={url + "hp-applications/accept"}
            element={<HpApplicationAccept />}
          />
          <Route
            path={url + "hp-applications/reject"}
            element={<HpApplicationReject />}
          />
          <Route
            path={url + "hp-applications/appeal/:singpassUrl"}
            element={<HpApplicationApeal />}
          />
          <Route
            path={url + "hp-applications/view-application"}
            element={<NewViewApplication />}
          />
          <Route
            path={url + "hp-applications/view-application/tabs"}
            element={<ViewApplication />}
          />
          <Route
            path={url + "hp-applications/create/guarantor/:singpassUrl"}
            element={<CreateGuarantor />}
          />
          <Route
            path={url + "hp-applications/edit/:singpassUrl"}
            element={<EditHpApplication />}
          />
          <Route path={url + "account-login"} element={<Accounts />} />
          <Route path={url + "reports"} element={<Reports />} />
          <Route path={url + "*"} element={<div>Page not Found</div>} />
        </Routes>
        <ScrollToTop />
      </Suspense>
    </>
  );
}
