import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: false,
  signal: false,
};

export const filterSlice = createSlice({
  name: "filterStatus",
  initialState,
  reducers: {
    filterStatus: (state, action) => {
      state.value = action.payload;
    },
    filterBtnSignal: (state, action) => {
      state.signal = action.payload;
    },
  },
});

export const { filterStatus, filterBtnSignal } = filterSlice.actions;

export default filterSlice.reducer;
