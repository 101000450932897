import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "../slice/Login/loginSlice";
import loginUserReducer from "../slice/Login/loginUserSlice";
import filterReducer from "../slice/Filter/filterSlice";
import filterFormData from "../slice/Filter/filterData";
import saveSlice from "../slice/SaveHpApplication/saveSlice";
import signalReducer from "../slice/signalSlice";

export const store = configureStore({
  reducer: {
    loginStatus: loginReducer,
    loginUserInfo: loginUserReducer,
    filterStatus: filterReducer,
    filterFormData: filterFormData,
    signal: signalReducer,
    saveStatus: saveSlice,
  },
});
