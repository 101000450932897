import React from "react";
// ----Images---- //
import Image from "../../helper/images";
// ----Style---- //
import "../../styles/scss/loader.scss";

export default function Loader() {
  return (
    <>
      {/* loader-code-start */}
      <div className="loader_wrapper d-flex justify-content-center align-items-center">
        <div className="loader_wrapper-inner"></div>
        <div className="loader_wrapper-static"></div>
        <img src={Image.logo.logo_2} alt="" width="35" />
      </div>
      {/* loader-code-end */}
    </>
  );
}
