import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: {
    loanDetails: false,
    applicantDetails: false,
    guarantorDetails: false,
    uploadDocument: false,
    declaration: false,
    signature: false,
    docAddOn: false,
  },
};

export const saveSlice = createSlice({
  name: "Save Hp Application",
  initialState,
  reducers: {
    loanDetails: (state, action) => {
      state.value.loanDetails = action.payload;
    },
    applicantDetails: (state, action) => {
      state.value.applicantDetails = action.payload;
    },
    guarantorDetails: (state, action) => {
      state.value.guarantorDetails = action.payload;
    },
    uploadDocument: (state, action) => {
      state.value.uploadDocument = action.payload;
    },
    declaration: (state, action) => {
      state.value.declaration = action.payload;
    },
    signature: (state, action) => {
      state.value.signature = action.payload;
    },
    docAddOn: (state, action) => {
      state.value.docAddOn = action.payload;
    },
  },
});

export const {
  loanDetails,
  applicantDetails,
  guarantorDetails,
  uploadDocument,
  declaration,
  signature,
  docAddOn,
} = saveSlice.actions;

export default saveSlice.reducer;
